export const groupBy = (array, prop) => {
  return array.reduce((groups, item) => {
      let val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
  }, {});
}

export const isMobileDevice = () => {
  // FIXME: window.orientation API is deprecated.
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}