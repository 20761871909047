import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Popper from '@material-ui/core/Popper';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SettingsIcon from '@material-ui/icons/Settings';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CropIcon from '@material-ui/icons/Crop';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Slide from '@material-ui/core/Slide';
import * as bimU from 'bimu.io.viewer';
import { ARStatesEnum } from './ARStatesEnum';
import { groupBy } from './Utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
  containerHidden: {
    display: "none"
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  target: {
    width: "60px",
    height: "50px",
    objectFit: "cover",
    objectPosition: "0 0",
  },

  // https://github.com/google/model-viewer/blob/master/packages/modelviewer.dev/examples/augmentedreality/index.html
  planeModeInstruction: {
    width: "50px",
    height: "54px",
    animation: "$circle 3s linear infinite"
  },
  moveAround: {
    animation: "$elongate 1.5s infinite ease-in-out alternate"
  },
  "@keyframes circle": {
    "from": { transform: "translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg)" },
    "to": { transform: "translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg)" }
  },
  "@keyframes elongate": {
    "from": { transform: "translateX(100px)" },
    "to": { transform: "translateX(-50px)" }
  },

  // https://stackoverflow.com/questions/41907250/zoom-in-zoom-out-img-automatically-using-css-only
  tapOnPlane: {
    width: "25px",
    height: "36px",
    animation: "$zoom 1s infinite"
  },
  "@keyframes zoom": {
    "0%": {
      transform: "scale(1,1)",
    },
    "50%": {
      transform: "scale(1.5,1.5)",
    },
    "100%": {
      transform: "scale(1,1)",
    }
  },

  actionPopover: {
    width: "100%",
    height: "200px",
    position: 'absolute',
    bottom: 0,
    right: 0,
    background: "rgba(30,30,31,0.8)", // theme.palette.primary.dark
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionImage: {
    width: "90px",
    height: "75px",
    objectFit: "cover",
    objectPosition: "0 0",
    filter: "invert(1)"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  instructionText: {
    display: "block",
    textAlign: "center",
    color: '#fff',
    textShadow: "black 0.1em 0.1em 0.2em",
    paddingTop: "100px"
  },
  moveButton: {
    minWidth: "30px",
    paddingLeft: "12px",
    paddingRight: "0px",
    margin: "10px",
  },
  popper: {
    width: "90vw",
    maxWidth: "500px",
    maxHeight: "calc(100vh - 250px)",
    marginTop: "20px",
    background: "rgba(30,30,31,0.9)", // theme.palette.primary.dark
  },
  popperContent: {
    overflowY: "scroll",
    maxHeight: "calc(100vh - 320px)",
    width: "100%",
    padding: "10px"
  },
  tableCell: {
    fontSize: "8pt",
    padding: "3px 16px 3px 8px"
  },
  tableCellLarge: {
    fontSize: "10pt",
    padding: "3px 16px 3px 8px"
  },
}));

const ACTION_STATE = { CLOSED: 0, SCALE: 1, ROTATE: 2, MOVE: 3, SECTION: 4, SELECT: 5, RESET: 6, HELP: 7, EXIT: 8 };

const actions = [
  { icon: <ZoomInIcon />, name: 'Scale', state: ACTION_STATE.SCALE, instruction: "Make model bigger or smaller." },
  { icon: <ThreeSixtyIcon />, name: 'Rotate', state: ACTION_STATE.ROTATE, instruction: "Rotate model around itself." },
  { icon: <OpenWithIcon />, name: 'Move', state: ACTION_STATE.MOVE, instruction: "Adjust model positions in X, Y, and Z directions." },
  { icon: <CropIcon />, name: 'Section', state: ACTION_STATE.SECTION, instruction: "Turn on section planes in X, Y, and Z directions." },
  { icon: <TouchAppIcon />, name: 'Select', state: ACTION_STATE.SELECT, instruction: "Select a model element and view its properties." },
  { icon: <SettingsBackupRestoreIcon />, name: 'Reset', state: ACTION_STATE.RESET, instruction: "Reset position, scale, rotation, sectioning, etc." },
  { icon: <HelpOutlineIcon />, name: 'Help', state: ACTION_STATE.HELP, instruction: "Display help information." },
  { icon: <ExitToAppIcon />, name: 'Exit', state: ACTION_STATE.EXIT, instruction: "Terminate current AR session." },
];

// Action increments
const scaleMultiplier = 1.1;
const rotationIncrement = 10; // degrees
const displacementIncrement = 0.01;
const sectioningSliderDefaultValue = [-1.0, 1.0];

// Model states
let scale = 1.0; // relative scale
let rotation = 0;
let displacementX = 0;
let displacementY = 0;
let displacementZ = 0;
let prevSectioningPositiveX = 1.0;
let prevSectioningPositiveY = 1.0;
let prevSectioningPositiveZ = 1.0;
let prevSectioningNegativeX = -1.0;
let prevSectioningNegativeY = -1.0;
let prevSectioningNegativeZ = -1.0;

const ARDOMOverlayUI = ({ currentARState, domId, getViewer }) => {
  const classes = useStyles();
  const [currentActionState, setCurrentActionState] = React.useState(ACTION_STATE.CLOSED);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarContent, setSnackbarContent] = React.useState({ message: "", severity: "error" });
  const [sectioningX, setSectioningX] = React.useState(sectioningSliderDefaultValue);
  const [sectioningY, setSectioningY] = React.useState(sectioningSliderDefaultValue);
  const [sectioningZ, setSectioningZ] = React.useState(sectioningSliderDefaultValue);
  const [speedDialOpen, setSpeedDialOpen] = React.useState(false);
  const popperDefaultValue = { open: false, table: null };
  const [popper, setPopper] = React.useState(popperDefaultValue);
  const popperAnchor = React.useRef(null);
  const [firstTimeHelpDialogShown, setFirstTimeHelpDialogShown] = React.useState(false);

  React.useEffect(() => {
    if (currentARState == ARStatesEnum.LOADED && !firstTimeHelpDialogShown) {
      showHelpDialog();
      setFirstTimeHelpDialogShown(true);
    }
  }, [currentARState]);

  const handleBackdropClose = () => {
    setCurrentActionState(ACTION_STATE.CLOSED);
    handlePopperClose();
    const viewer = getViewer();
    viewer.toggleARSelectElement(false);
    viewer.unselectAllElements();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSpeedDialClose = () => {
    setSpeedDialOpen(false);
  };

  const handleSpeedDialOpen = () => {
    setSpeedDialOpen(true);
  };

  const handlePopperClose = () => {
    setPopper(popperDefaultValue);
  };

  const handleActionClick = (state) => {
    setSpeedDialOpen(false);
    if (state < ACTION_STATE.RESET) {
      setCurrentActionState(state);
    } else {
      setCurrentActionState(ACTION_STATE.CLOSED);
    }
    if (state == ACTION_STATE.SELECT) {
      const viewer = getViewer();
      viewer.toggleARSelectElement(true);
      setTimeout(() => { // Workaround to delay snack bar message
        showSnackbar("Aim at an element to select.", "info");
      }, 300);
    } else if (state == ACTION_STATE.RESET) {
      const viewer = getViewer();
      viewer.resetARScale();
      scale = 1.0;
      viewer.setARRotationOnXY(0);
      rotation = 0;
      viewer.translateARPosition(new THREE.Vector3(-displacementX, 0, 0));
      viewer.translateARPosition(new THREE.Vector3(0, -displacementY, 0));
      viewer.translateARPosition(new THREE.Vector3(0, 0, -displacementZ));
      displacementX = 0;
      displacementY = 0;
      displacementZ = 0;
      resetSectioning();
    } else if (state == ACTION_STATE.HELP) {
      showHelpDialog();
    } else if (state == ACTION_STATE.EXIT) {
      const viewer = getViewer();
      viewer.exitAR();
      // onSessionEnded callback will then redirect users to the share page
    }
  };

  const moveModel = (direction) => {
    const viewer = getViewer();
    if (direction === "X+") {
      viewer.translateARPosition(new THREE.Vector3(displacementIncrement, 0, 0));
      displacementX += displacementIncrement;
    } else if (direction === "X-") {
      viewer.translateARPosition(new THREE.Vector3(-displacementIncrement, 0, 0));
      displacementX -= displacementIncrement;
    } else if (direction === "Y+") {
      viewer.translateARPosition(new THREE.Vector3(0, displacementIncrement, 0));
      displacementY += displacementIncrement;
    } else if (direction === "Y-") {
      viewer.translateARPosition(new THREE.Vector3(0, -displacementIncrement, 0));
      displacementY -= displacementIncrement;
    } else if (direction === "Z+") {
      viewer.translateARPosition(new THREE.Vector3(0, 0, displacementIncrement));
      displacementZ += displacementIncrement;
    } else if (direction === "Z-") {
      viewer.translateARPosition(new THREE.Vector3(0, 0, -displacementIncrement));
      displacementZ -= displacementIncrement;
    }
    resetSectioning();
  };

  const rotateModel = (direction) => {
    const viewer = getViewer();
    const increment = direction > 0 ? rotationIncrement : -rotationIncrement;
    viewer.setARRotationOnXY(rotation);
    rotation += increment;
    resetSectioning();
  };

  const scaleModel = (direction) => {
    const viewer = getViewer();
    if (direction > 0) {
      scale *= scaleMultiplier;
    } else {
      scale /= scaleMultiplier;
    }
    viewer.setARScale(scale);
    resetSectioning();
  };

  const showSnackbar = (message, severity) => {
    setSnackbarContent({ message: message, severity: severity });
    setSnackbarOpen(true);
  }

  const triggerSelectElement = () => {
    const viewer = getViewer();
    viewer.triggerARSelectElement();
    const selectedElementIndices = viewer.getElementIndicesBySelection();
    if (!selectedElementIndices || selectedElementIndices.length == 0) {
      showSnackbar("No element selected.", "error");
    } else {
      showSnackbar(`Element index ${selectedElementIndices[0]} selected.`, "success");
    }
  };

  const viewElementProperties = (evt) => {
    const viewer = getViewer();
    const selectedElementIndices = viewer.getElementIndicesBySelection();
    if (!selectedElementIndices || selectedElementIndices.length == 0) {
      showSnackbar("No element selected.", "error");
      return;
    }
    viewer.getElementDataByIndex(
      selectedElementIndices[0],
      (data) => {
        const groupedProperties = groupBy(data.properties, "group");
        const tableContent = Object.entries(groupedProperties).map(([groupName, properties]) => (
          <React.Fragment key={groupName}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} align="left" colSpan={2}>
                  <Box fontSize={16} fontWeight={600} color="secondary.dark">
                    {groupName}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((property) => (
                <TableRow key={property.name}>
                  <TableCell className={classes.tableCell} align="left">{property.name}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{property.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </React.Fragment>
        ));
        setPopper({ open: true, table: <Table size="small">{tableContent}</Table> });
      },
      (e) => {
        showSnackbar("Failed to retrieve element properties.", "error");
      }
    );
  };

  // Disable for now since custom material is not being used by default
  /* const hideElement = () => {
    const viewer = getViewer();
    const selectedElementIndices = viewer.getElementIndicesBySelection();
    if (!selectedElementIndices || selectedElementIndices.length == 0) {
      showSnackbar("No element selected.", "error");
      return;
    }
    viewer.hideSelectedElements();
  }; */

  const handleSectioningChange = (direction, percentages) => {
    const viewer = getViewer();
    if (direction === "X") {
      setSectioningX(percentages);
      const diffNegativeX = (percentages[0] - prevSectioningNegativeX) / 2;
      if (diffNegativeX != 0) {
        viewer.moveARSectionPlane(0, diffNegativeX);
      }
      const diffPositiveX = (prevSectioningPositiveX - percentages[1]) / 2;
      if (diffPositiveX != 0) {
        viewer.moveARSectionPlane(1, diffPositiveX);
      }
      prevSectioningNegativeX = percentages[0];
      prevSectioningPositiveX = percentages[1];
    } else if (direction === "Y") {
      setSectioningY(percentages);
      const diffNegativeY = (percentages[0] - prevSectioningNegativeY) / 2;
      if (diffNegativeY != 0) {
        viewer.moveARSectionPlane(2, diffNegativeY);
      }
      const diffPositiveY = (prevSectioningPositiveY - percentages[1]) / 2;
      if (diffPositiveY != 0) {
        viewer.moveARSectionPlane(3, diffPositiveY);
      }
      prevSectioningNegativeY = percentages[0];
      prevSectioningPositiveY = percentages[1];
    } else if (direction === "Z") {
      setSectioningZ(percentages);
      const diffNegativeZ = (percentages[0] - prevSectioningNegativeZ) / 2;
      if (diffNegativeZ != 0) {
        viewer.moveARSectionPlane(4, diffNegativeZ);
      }
      const diffPositiveZ = (prevSectioningPositiveZ - percentages[1]) / 2;
      if (diffPositiveZ != 0) {
        viewer.moveARSectionPlane(5, diffPositiveZ);
      }
      prevSectioningNegativeZ = percentages[0];
      prevSectioningPositiveZ = percentages[1];
    }
  }

  const resetSectioning = () => {
    setSectioningX(sectioningSliderDefaultValue);
    setSectioningY(sectioningSliderDefaultValue);
    setSectioningZ(sectioningSliderDefaultValue);
    if (
      prevSectioningPositiveX != 1.0 ||
      prevSectioningPositiveY != 1.0 ||
      prevSectioningPositiveZ != 1.0 ||
      prevSectioningNegativeX != -1.0 ||
      prevSectioningNegativeY != -1.0 ||
      prevSectioningNegativeZ != -1.0
    ) {
      showSnackbar("Section planes removed.", "info");
    }
    prevSectioningPositiveX = 1.0;
    prevSectioningPositiveY = 1.0;
    prevSectioningPositiveZ = 1.0;
    prevSectioningNegativeX = -1.0;
    prevSectioningNegativeY = -1.0;
    prevSectioningNegativeZ = -1.0;
  };

  const showMenuTooltip = (e) => {
    if (
      e.touches.length == 2 && // Two fingers touch starting
      currentARState == ARStatesEnum.LOADED && 
      currentActionState == ACTION_STATE.CLOSED && 
      !speedDialOpen
    ) {
      setPopper({ open: true, table: <Typography>Touch and pinch are not supported yet. Use the floating action menu to navigate 3D model.</Typography> });
    }
  };

  const showHelpDialog = () => {
    const tableContent = (
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="left" colSpan={3}>
              <Box fontSize={16} fontWeight={600} color="secondary.dark">
                Navigate BIM Model in Augmented Reality
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((action) => (
            <TableRow key={action.name}>
              <TableCell className={classes.tableCellLarge} align="left">{action.icon}</TableCell>
              <TableCell className={classes.tableCellLarge} align="left">{action.name}</TableCell>
              <TableCell className={classes.tableCell} align="left">{action.instruction}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
    setPopper({ open: true, table: tableContent });
  };

  const preventClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const popperPlaceholder = (
    <Popper open={popper.open} anchorEl={popperAnchor.current} placement="bottom" disablePortal>
      <Paper className={classes.popper} elevation={1} variant="outlined" onClick={preventClick}>
        <div className={classes.popperContent}>
          {popper.table}
        </div>
        <DialogActions>
          <Button onClick={handlePopperClose} color="secondary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Paper>
    </Popper>
  );

  return (
    <div id={domId} className={currentARState > ARStatesEnum.NOT_STARTED ? classes.container : classes.containerHidden} ref={popperAnchor} >
      {
        currentARState == ARStatesEnum.INITIALIZATION &&
        <img src="/images/preloader.gif" />
      }
      {
        currentARState == ARStatesEnum.HIT_TEST_RESULT_FOUND &&
        <Box textAlign="center">
          <Box pt="200px">
            <img className={classes.tapOnPlane} src="/images/ar_control.svg" />
          </Box>
          <Typography component="div" className={classes.instructionText} variant="h6">
            Tap here to <br /> place model
          </Typography>
        </Box>
      }
      {
        currentARState == ARStatesEnum.HIT_TEST_RESULT_LOST &&
        <Box textAlign="center">
          <Box pt="200px" className={classes.moveAround}>
            <img className={classes.planeModeInstruction} src="/images/hand.png" />
          </Box>
          <Typography component="div" className={classes.instructionText} variant="h6">
            Move your phone around <br /> to find a plane
          </Typography>
        </Box>
      }
      {
        currentActionState == ACTION_STATE.SELECT &&
        <img className={classes.target} src="/images/noun_Target_1570084.png" />
      }
      {
        currentARState == ARStatesEnum.LOADED && currentActionState == ACTION_STATE.CLOSED &&
        <React.Fragment>
          <Box width="100%" height="100%" onTouchStart={showMenuTooltip} />
          <SpeedDial
            ariaLabel="SpeedDial example" // Must have this prop
            className={classes.speedDial}
            hidden={false} // Must be false otherwise cannot be used in DOM overlay. Not sure why though.
            icon={<SettingsIcon />}
            onClose={handleSpeedDialClose}
            onOpen={handleSpeedDialOpen}
            open={speedDialOpen}
            direction='up'
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipOpen
                tooltipTitle={action.name}
                onClick={() => handleActionClick(action.state)}
              />
            ))}
          </SpeedDial>
        </React.Fragment>
      }
      <Backdrop className={classes.backdrop} invisible={true} open={currentActionState > ACTION_STATE.CLOSED} onClick={handleBackdropClose}>
        <Box className={classes.actionPopover} onClick={preventClick}>
          {
            currentActionState == ACTION_STATE.SCALE &&
            <React.Fragment>
              <Box display="inline-block" m={2}>
                <Box component="div" textAlign="center" color="white" fontWeight={600} fontSize={25} mb={1}>
                  Scale
                </Box>
                <img className={classes.actionImage} src="/images/noun_enlarge_621019.png" />
              </Box>
              <Box display="inline-block" textAlign="center" m={2}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<RemoveIcon style={{ fontSize: 40 }} />} onClick={() => scaleModel(-1.0)} />
                <Box color="white" fontSize={15}>
                  Scale Down
                </Box>
              </Box>
              <Box display="inline-block" textAlign="center" m={2}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<AddIcon style={{ fontSize: 40 }} />} onClick={() => scaleModel(1.0)} />
                <Box color="white" fontSize={15}>
                  Scale Up
                </Box>
              </Box>
            </React.Fragment>
          }
          {
            currentActionState == ACTION_STATE.ROTATE &&
            <React.Fragment>
              <Box display="inline-block" m={2}>
                <Box component="div" textAlign="center" color="white" fontWeight={600} fontSize={25} mb={1}>
                  Rotate
                </Box>
                <img className={classes.actionImage} src="/images/noun_all round view_3127995.png" />
              </Box>
              <Box display="inline-block" textAlign="center" m={2}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<RotateLeftIcon style={{ fontSize: 40 }} />} onClick={() => rotateModel(1.0)} />
                <Box color="white" fontSize={15}>
                  Counterclockwise
                </Box>
              </Box>
              <Box display="inline-block" textAlign="center" m={2}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<RotateRightIcon style={{ fontSize: 40 }} />} onClick={() => rotateModel(-1.0)} />
                <Box color="white" fontSize={15}>
                  Clockwise
                </Box>
              </Box>
            </React.Fragment>
          }
          {
            currentActionState == ACTION_STATE.MOVE &&
            <React.Fragment>
              <Box display="inline-block" m={2}>
                <Box component="div" textAlign="center" color="white" fontWeight={600} fontSize={25} mb={1}>
                  Move
                </Box>
                <img className={classes.actionImage} src="/images/noun_3D Coordinates_319786.png" />
              </Box>
              <Box display="inline-block" textAlign="center" m={1}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<AddIcon />} onClick={() => moveModel("X+")} />
                <Typography variant="h6">
                  X
                </Typography>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<RemoveIcon />} onClick={() => moveModel("X-")} />
              </Box>
              <Box display="inline-block" textAlign="center" m={1}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<AddIcon />} onClick={() => moveModel("Y+")} />
                <Typography variant="h6">
                  Y
                </Typography>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<RemoveIcon />} onClick={() => moveModel("Y-")} />
              </Box>
              <Box display="inline-block" textAlign="center" m={1}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<AddIcon />} onClick={() => moveModel("Z+")} />
                <Typography variant="h6">
                  Z
                </Typography>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<RemoveIcon />} onClick={() => moveModel("Z-")} />
              </Box>
            </React.Fragment>
          }
          {
            currentActionState == ACTION_STATE.SECTION &&
            <React.Fragment>
              <Box display="inline-block" m={1}>
                <Box component="div" textAlign="center" color="white" fontWeight={600} fontSize={20} mb={1}>
                  Section<br />Planes
                </Box>
                <img className={classes.actionImage} src="/images/noun_Merge_1369035.png" />
              </Box>
              <Box width="calc(100vw - 150px)" display="inline-block" textAlign="center" m={1}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      X-
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider color="secondary" min={-1.0} step={0.01} max={1.0} value={sectioningX} onChange={(e, v) => handleSectioningChange("X", v)} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      X+
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Y-
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider color="secondary" min={-1.0} step={0.01} max={1.0} value={sectioningY} onChange={(e, v) => handleSectioningChange("Y", v)} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Y+
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Z-
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider color="secondary" min={-1.0} step={0.01} max={1.0} value={sectioningZ} onChange={(e, v) => handleSectioningChange("Z", v)} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Z+
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          }
          {
            currentActionState == ACTION_STATE.SELECT &&
            <React.Fragment>
              <Box display="inline-block" m={2}>
                <Box component="div" textAlign="center" color="white" fontWeight={600} fontSize={20} mb={1}>
                  Select<br />Element
                </Box>
                <img className={classes.actionImage} src="/images/noun_Augmented Reality_3329498.png" />
              </Box>
              <Box display="inline-block" textAlign="center" m={2}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<TouchAppIcon style={{ fontSize: 40 }} />} onClick={triggerSelectElement} />
                <Box color="white" fontSize={15}>
                  Select<br />Element
                </Box>
              </Box>
              <Box display="inline-block" textAlign="center" m={2}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<FormatListBulletedIcon style={{ fontSize: 40 }} />} onClick={viewElementProperties} />
                <Box color="white" fontSize={15}>
                  View Element<br />Properties
                </Box>
              </Box>
              {/* <Box display="inline-block" textAlign="center" m={1}>
                <Button className={classes.moveButton} variant="contained" color="secondary" size="large" startIcon={<VisibilityOffIcon style={{ fontSize: 40 }} />} onClick={hideElement} />
                <Box color="white" fontSize={15}>
                  Hide Element
                </Box>
              </Box> */}
            </React.Fragment>
          }
        </Box>
        {popperPlaceholder} {/* For element properties dialog */}
      </Backdrop>
      {currentActionState == ACTION_STATE.CLOSED && popperPlaceholder} {/* For help dialog */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
      >
        <Alert variant="filled" severity={snackbarContent.severity}>
          {snackbarContent.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ARDOMOverlayUI;