import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import LanguageIcon from '@material-ui/icons/Language';
import BookIcon from '@material-ui/icons/Book';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

function Copyright() {
  return (
    <React.Fragment>
      {'Copyright © '}
      <Link color="inherit" href="https://bimu.io/" target="_blank">
        Transformosa Private Limited
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    //textAlign: 'center',
  },
  iconsWrapper: {
    //height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];

export default function AppFooter() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.iconsWrapper}
          spacing={2}
        >
          <Grid item className={classes.icons}>
            <a href="https://bimu.io/" target="_blank" className={classes.icon}>
              <LanguageIcon fontSize="large" color="primary" />
            </a>
            <a href="https://twitter.com/TransformosaUK" target="_blank" className={classes.icon}>
              <TwitterIcon fontSize="large" color="primary" />
            </a>
            <a href="https://www.facebook.com/transformosa" target="_blank" className={classes.icon}>
              <FacebookIcon fontSize="large" color="primary" />
            </a>
            <a href="https://medium.com/transformosa-blog" target="_blank" className={classes.icon}>
              <BookIcon fontSize="large" color="primary" />
            </a>
            <a href="https://www.youtube.com/channel/UCW4NVA53RqJUfjJe3gao1kA" target="_blank" className={classes.icon}>
              <YouTubeIcon fontSize="large" color="primary" />
            </a>
            <a href="https://www.linkedin.com/company/transformosa" target="_blank" className={classes.icon}>
              <LinkedInIcon fontSize="large" color="primary" />
            </a>
          </Grid>
          <Grid item >
            <Copyright />
          </Grid>
          <Grid item >
            <Link color="inherit" onClick={handleClickOpen} href="#" variant="caption">
              Licences
            </Link>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">Licences</DialogTitle>
          <DialogContent>
            <Typography variant="caption">
              {'Icons made by '}
              <Link href="https://www.freepik.com" rel="sponsored" title="Freepik">
                Freepik
              </Link>
              {' from '}
              <Link href="https://www.flaticon.com" rel="sponsored" title="Flaticon">
                www.flaticon.com
              </Link>
              {' is licensed by '}
              <Link
                href="https://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </Link>
              <br />Augmented Reality by Oleksandr Panasovskyi from the Noun Project
              <br />Augmented Reality by Dot9 from the Noun Project
              <br />Augmented Reality by Wichai Wi from the Noun Project
              <br />Augmented Reality by Path Lord from the Noun Project
              <br />Augmented Reality by KARNA RAJA from the Noun Project
              <br />AR 3D by Sinistrad from the Noun Project
              <br />ar by Sinistrad from the Noun Project
              <br />augmented reality 360 by VINZENCE STUDIO from the Noun Project
              <br />Augmented Reality by tulpahn from the Noun Project
              <br />all round view by Andrei Yushchenko from the Noun Project
              <br />Box by Three Six Five from the Noun Project
              <br />Box by Alice Design from the Noun Project
              <br />Merge by Andrejs Kirma from the Noun Project
              <br />Target by Phoenix Dungeon from the Noun Project
              <br />Target by M Yudi Maulana from the Noun Project
              <br />Target by Vectorstall from the Noun Project
              <br />Target by arjuazka from the Noun Project
              <br />Target by Vector Valley from the Noun Project
              <br />Scalability by Oleksandr Panasovskyi from the Noun Project
              <br />large by Timofei Rostilov from the Noun Project
              <br />enlarge by creative outlet from the Noun Project
              <br />Move by Viktor Fedyuk (Tim P) from the Noun Project
              <br />Move by Oliver Kittler from the Noun Project
              <br />3D Coordinates by Ben Davis from the Noun Project
              <br />upload house by arejoenah from the Noun Project
              <br />sharing lock by DinosoftLab from the Noun Project
              <br /><a target="_blank" href="https://icons8.com/icons/set/augmented-reality">Augmented Reality icon</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
              <br />Icons made by <a href="https://smashicons.com/" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
              <br />Icons made by <a href="https://www.flaticon.com/authors/kiranshastry" title="Kiranshastry">Kiranshastry</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
              <br /><div>Icons made by <a href="https://www.flaticon.com/free-icon/augmented-reality_1401977?term=augmented%20reality&page=1&position=12&related_item_id=1401977" title="turkkub">turkkub</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Typography>
  );
}
