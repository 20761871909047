import React from 'react';
import { Navigate } from 'react-router-dom';
import Home from './Home';
import NotFoundView from './modules/webxr/NotFoundView';

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/models/:modelId',
    element: <Home />,
  },
  {
    path: '/share/:modelId',
    element: <Home />,
  },
  {
    path: '404',
    element: <NotFoundView />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  }
];

export default routes;