import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Link from '@material-ui/core/Link';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

const faqList = [
  {
    question: "What is bimU.io Reality?",
    answer: "bimU.io Reality is a web-based, full-featured, easy-to-use Augmented Reality application that overlays BIM models into the physical world — as if they’re actually there with you, in your own space. It is the best stakeholder engagement tool that offers interactivity and intuitiveness for facilitating 3D model navigation and BIM design review."
  },
  {
    question: "How do I view my own BIM model in Augmented Reality (AR)?",
    answer: <span>Please upload your BIM model to <Link color="secondary" href="https://bimu.io" target="_blank">bimU.io Viewer</Link> in the first instance. Then click the <Link color="secondary" href="https://imgur.com/a/CVoPiDT" target="_blank">View in AR/VR</Link> button or simply paste your Model ID above.</span>
  },
  {
    question: "Is there a step-by-step tutorial?",
    answer: <span>Check out <Link color="secondary" href="https://www.youtube.com/watch?v=gkbKzWJbeqY" target="_blank">this video</Link> and <Link color="secondary" href="http://bit.ly/bim-ar" target="_blank">this blog post</Link> for detailed instructions.</span>
  },
  {
    question: "What mobile browsers are supported?",
    answer: <span>Please use <Link color="secondary" href="https://play.google.com/store/apps/details?id=com.android.chrome" target="_blank">Google Chrome</Link> on Android or <Link color="secondary" href="https://apps.apple.com/tw/app/webxr-viewer/id1295998056" target="_blank">WebXR Viewer</Link> on iOS.</span>
  },
  {
    question: "Are there hardware/software requirements?",
    answer: <span>You need an ARCore or ARKit compatible device on Android or iOS respectively. See the full list <Link color="secondary" href="https://developers.google.com/ar/discover/supported-devices" target="_blank">here</Link>. Most mobile phones and tablets support AR already. An error message will be shown above if your device is not AR ready. Please also update your operating system to the latest version and use a compatible mobile web browser to view this website.</span>
  },
  {
    question: "What is the difference between Plane Mode and Floating Mode?",
    answer: "There two ways to anchor virtual 3D content in real-world environment. In the Plane Mode, you'll firstly move around camera to detect a plane area, such as floor, table, etc. Then you can tap to place a BIM model on a plane found. This is used for environments in which you can physically move around. When it is hard to find a plane in surrounding environment, you can consider using the Floating Mode that simply places a BIM model in front of your camera. Spatial tracking might be lost if you move too far away from the initial position."
  },
  {
    question: "How come I can't detect a plane?",
    answer: "The underlying principle of plane detection is computer vision. It doesn't work well in a dark environment. Please also provide more context to your environment, such as placing some objects on a table, looking at floor tiles with pattern, etc."
  },
  {
    question: "Why my model fails to load?",
    answer: "bimU.io Reality is an experimental feature. Firstly, please make sure you use a compatible device and a supported browser. Mobile devices are generally not as powerful as desktop computers. You might consider using a high-performance mobile device, such as Apple iPad Pro."
  },
  {
    question: "Why is my model flickering?",
    answer: "Your model might become flickering intermittently when device memory usage is too high. Try to close other apps or restart your device."
  },
  {
    question: "Is it possible to view a large-sized model?",
    answer: "Probably not. It depends on how good your hardware is. You will see a warning if your model might be too large to display. Apple iPad Pro is generally recommended for viewing a large-sized model."
  },
  {
    question: "How is bimU.io Reality different from bimU.io Viewer?",
    answer: "bimU.io Reality can be seen as a plug-in for navigating BIM models hosted on bimU.io Viewer in Augmented Reality."
  },
  {
    question: "Is bimU.io Reality free to use?",
    answer: "bimU.io Reality is a companion tool to bimU.io Viewer and therefore always free to use. All bimU.io functionality will remain freely available until 30th June 2021. You will need a bimU.io Viewer Pro subscription to upload a large-sized model after then."
  },
  {
    question: "Will Virtual Reality (VR) be supported?",
    answer: "Absolutely! We are developing VR functionality. Please contact us if you happen to have an Oculus Quest device and want to be an early adopter."
  },
  {
    question: "What kind of support is available for bimU.io Reality?",
    answer: <span>Please feel free to submit feedback or suggestions to <Link color="secondary" href="mailto:support@bimu.io" target="_blank">support@bimu.io</Link> and we'll be in touch as soon as we can.</span>
  }
];

function ProductFAQ(props) {
  const { classes } = props;
  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          FAQ
        </Typography>
        <div>
          {
            faqList.map((faqItem, index) => (
              <Accordion key={`panel${index}d`} square expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                  {faqItem.question}
                </AccordionSummary>
                <AccordionDetails>
                  {faqItem.answer}
                </AccordionDetails>
              </Accordion>
            ))
          }
        </div>
      </Container>
    </section>
  );
}

ProductFAQ.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductFAQ);
