import React, { useEffect } from "react";
import withRoot from '../withRoot';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { QRNormal } from 'react-qrbtf';
import FlatButton from '../components/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },
  textField: {
    minWidth: "70%",
    marginTop: "15px",
    marginRight: "15px",
  },
  button: {
    marginTop: "15px",
  },
}));

const shareableLinkTextFieldId = "shareable-link";
const copyLinkDefaultText = "Copy Link";

const ShareDialog = ({ open, closeDialog, shareableLink }) => {
  const classes = useStyles();

  const [copyLinkButtonText, setCopyLinkButtonText] = React.useState(copyLinkDefaultText);

  const copyLink = () => {
    let shareableLinkTextField = document.getElementById(shareableLinkTextFieldId);
    shareableLinkTextField.select();
    document.execCommand("copy");
    setCopyLinkButtonText("Copied!");
  }

  const handleClose = () => {
    closeDialog();
    setCopyLinkButtonText(copyLinkDefaultText);    
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Copy Link</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Copy the link or scan the QR code below. You'll need to use <Link color="secondary" href="https://play.google.com/store/apps/details?id=com.android.chrome" target="_blank">Google Chrome</Link> on Android or <Link color="secondary" href="https://apps.apple.com/tw/app/webxr-viewer/id1295998056" target="_blank">WebXR Viewer</Link> on iOS to view your model in Augmented Reality.
        </DialogContentText>
        <Box className={classes.container}>
          <TextField
            id={shareableLinkTextFieldId}
            className={classes.textField}
            margin="dense"
            value={shareableLink}
            variant="outlined"
            contentEditable={false}
          />
          <FlatButton
            className={classes.button}
            color="secondary"
            variant="contained"
            onClick={copyLink}
            size="small"
          >
            {copyLinkButtonText}
          </FlatButton>
        </Box>
        <Box className={classes.container}>
          <QRNormal
            value={shareableLink}
            className="my-qrcode"
            styles={{ svg: { width: "200px" } }}
            type="rect"
            size={80}
            opacity={100}
            posType="rect"
            otherColor="#FFFFFF"
            posColor="#FFFFFF"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRoot(ShareDialog);