import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductFAQ from './modules/views/ProductFAQ';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import Box from '@material-ui/core/Box';
import { viewerDomId } from './modules/webxr/Settings';

function Index() {

  React.useEffect(() => {
    window.scrollTo(0, 0); // Enforce to scroll to top for replaying current model
  });

  return (
    <React.Fragment>
      <ProductHero />
      <Box id={viewerDomId} display="none" mx="auto" width="100vw" height="100vh" />
      <ProductHowItWorks />
      <ProductFAQ />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
