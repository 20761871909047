import React, { useEffect } from "react";
import withRoot from '../../modules/withRoot';
import {
  Button,
  Box,
  Dialog,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import ReplayIcon from '@material-ui/icons/Replay';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    backgroundColor: theme.palette.primary.dark,
  },
  button: {
    margin: theme.spacing(1),
  },
  image: {
    width: "100px",
    height: "100px",
    filter: "invert(1)",
  },
}));

const ShareView = ({ onShareClick, onReplayClick, onUploadClick }) => {
  const classes = useStyles();

  const options = [
    { name: "Share", icon: <ShareIcon />, action: onShareClick },
    { name: "Replay", icon: <ReplayIcon />, action: onReplayClick },
    { name: "Upload", icon: <CloudUploadIcon />, action: onUploadClick },
  ];

  return (
    <Dialog fullScreen open={true} classes={{ paper: classes.container }}>
      <Box m={2} component="h2" >
        Thanks for using bimU.io Reality!
      </Box>
      <Box m={2} >
        <img className={classes.image} src="/images/augmented-reality-black.png" />
      </Box>      
      {
        options.map(option => (
          <Box>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={option.icon}
              onClick={option.action}
            >
              {option.name}
            </Button>
          </Box>
        ))
      }
    </Dialog>
  );
};

export default withRoot(ShareView);