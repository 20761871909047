import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Box,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ThreeImporter from './ThreeImporter'; // Must have Three.js imported before bimU.io Viewer API
import * as bimU from 'bimu.io.viewer';
import ARDOMOverlayUI from './ARDOMOverlayUI';
import { ARStatesEnum } from './ARStatesEnum';
import { viewerDomId, arUiDomId, iosUiContainerDomId, largeModelThreshold, sampleModelPassword } from './Settings';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    backgroundColor: theme.palette.primary.dark
  },
  arModeImage: {
    width: "120px",
    height: "100px",
    objectFit: "cover",
    objectPosition: "0 0",
    filter: "invert(1)"
  },
  buttonLabel: {
    display: "block"
  },
  button: {
    margin: "20px"
  },
  passwordInput: {
    margin: "20px",
    width: "300px",
    maxWidth: "80%"
  },
  modelLoadingProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px"
  },
}));

// Static instance
let viewer = null;
// Must use function otherwise the initial null reference will be passed down
const getViewer = () => {
  return viewer;
};

const ARDialog = ({ open, closeDialog, modelId, loadSampleModel }) => {
  const classes = useStyles();
  const [isModelLoaded, setIsModelLoaded] = React.useState(false);
  const [modelLoadingProgress, setModelLoadingProgress] = React.useState(-1);
  const [currentARState, setCurrentARState] = React.useState(ARStatesEnum.NOT_STARTED);
  const [modelPassword, setModelPasssword] = React.useState("");
  const [isViewerInitialized, setIsViewerInitialized] = React.useState(false);
  const [isModelFailedToLoad, setIsModelFailedToLoad] = React.useState(false);
  const [isLargeModel, setIsLargeModel] = React.useState(false);

  useEffect(() => {
    // Disable custom material
    window.disableCustomMaterial = true;
    // Disable custom raycaster
    window.disableCustomRaycaster = true;
  }, []);

  const checkLoadSampleModel = () => {
    if(loadSampleModel){
      loadModel();
    }
  }

  const initializeViewer = () => {
    // Viewer configuration object
    const viewerConfigs = {
      domElementId: viewerDomId,
      showUI: false,
      //showFPS: true
    };

    // Initialise a Viewer 
    viewer = new bimU.Viewer(viewerConfigs);
    viewer.initialize();
    viewer.setBackgroundColor(0x1e1e1f); // theme.palette.primary.dark

    // AR events
    viewer.addEventListener(bimU.EventsEnum.ON_AR_HIT_TEST_RESULT_FOUND, () => {
      setCurrentARState(ARStatesEnum.HIT_TEST_RESULT_FOUND);
    });
    viewer.addEventListener(bimU.EventsEnum.ON_AR_HIT_TEST_RESULT_LOST, () => {
      setCurrentARState(ARStatesEnum.HIT_TEST_RESULT_LOST);
    });
    viewer.addEventListener(bimU.EventsEnum.ON_AR_EXTENTS_UPDATED, () => {
      setCurrentARState(ARStatesEnum.LOADED);
    });

    setIsViewerInitialized(true);
  };

  const loadModel = () => {
    if(!isViewerInitialized){
      initializeViewer();
    }

    // Model configuration object
    const modelConfigs = {
      modelId: modelId,
      password: loadSampleModel ? sampleModelPassword : modelPassword
    };

    // Load a model
    let filePropertiesInitiated = false;
    setModelLoadingProgress(0.0);
    setIsModelFailedToLoad(false);
    viewer.loadModel(modelConfigs,
      (data) => { // onProgress
        setModelLoadingProgress(data.progress);
        if (!filePropertiesInitiated) {
          filePropertiesInitiated = true;
          viewer.getFileProperties(
            (data) => {
              console.log(data);
              if(data.size > largeModelThreshold){
                setIsLargeModel(true);
              }
            }, (error) => {
              console.log(error);
            }
          );
        }
      },
      () => { // onLoaded
        setIsModelLoaded(true);
        ga('send', {
          hitType: 'event',
          eventCategory: 'ModelLoaded',
          eventAction: modelId,
        });
      },
      (error) => { // onError
        console.log(error);
        setIsModelFailedToLoad(true);
      }
    );
  };

  const arModes = [
    { name: "Plane Mode", image: "/images/noun_Augmented Reality_1741259.png", enum: 1 },
    { name: "Floating Mode", image: "/images/noun_ar_2254890.png", enum: 0 }
  ];

  const runAR = (arMode) => {
    viewer.isWebArSupported((isSupported) => {
      if (isSupported) {
        setCurrentARState(ARStatesEnum.INITIALIZATION);

        // Make canvas visible
        document.getElementById(viewerDomId).style.display = "block";

        // Scroll to top to avoid AR DOM overlay offset
        window.scrollTo(0, 0);

        // Dirty workaround for iOS that also works on Anroid
        const domNode = document.getElementById(iosUiContainerDomId);
        domNode.appendChild(document.getElementById(arUiDomId));
        viewer.startAR(arMode, domNode, () => {
          // onSessionEnded callback
          window.location.replace(`/#/share/${modelId}`);
        });

        ga('send', {
          hitType: 'event',
          eventCategory: 'RunAR',
          eventAction: arMode,
        });

        // Below is sufficient to run on Android
        // viewer.startAR(arMode, document.getElementById(arUiDomId));
      } else {
        alert("WebXR is not supported on your device!");
      }
    });
  };

  return (
    <Dialog fullScreen open={open} onClose={closeDialog} classes={{ paper: classes.container }} onEntered={checkLoadSampleModel} >
      {currentARState == ARStatesEnum.NOT_STARTED && <DialogContent>
        {/* Model viewer */}
        {/* <Box id={viewerDomId} mx="auto" width="200px" height="200px" /> */}
        {!isModelLoaded && (isModelFailedToLoad || modelLoadingProgress == -1) &&
          <Box mt="50px">
            <Typography variant="h6" gutterBottom>
              Enter Password
            </Typography>
            <TextField
              className={classes.passwordInput}
              label="Password"
              type="password"
              variant="outlined"
              color="secondary"
              value={modelPassword}
              onChange={(e) => setModelPasssword(e.target.value)}
              error={isModelFailedToLoad}
              helperText={isModelFailedToLoad && "Wrong password, the content doesn't exist, or sharing is turned off."}
            />
            <br />
            <Button onClick={loadModel} className={classes.button} classes={{ label: classes.buttonLabel }} variant="contained" color="secondary">
              Load Model
            </Button>
          </Box>
        }
        {!isModelLoaded && modelLoadingProgress >= 0 && !isModelFailedToLoad &&
          <Box m="50px">
            <Typography variant="h6" gutterBottom>
              Loading Model...
            </Typography>
            <Box className={classes.modelLoadingProgress} position="relative">
              <CircularProgress color="secondary" size={100} />
              <Box position="absolute">
                <Typography variant="h6">
                  {modelLoadingProgress} %
              </Typography>
              </Box>
            </Box>
          </Box>
        }        
        {isModelLoaded &&
          <Box m="50px">
            <Typography variant="h6" gutterBottom>
              Select AR Mode
            </Typography>
            {arModes.map(mode => (
              <Button key={mode.name} onClick={() => runAR(mode.enum)} className={classes.button} classes={{ label: classes.buttonLabel }} variant="contained" color="secondary">
                <img className={classes.arModeImage} src={mode.image} />
                <Box>{mode.name}</Box>
              </Button>
            ))}
          </Box>
        }
        {isLargeModel &&
          <Alert variant="filled" severity="warning">
            This model may not be loaded successfully in Augmented Reality due to its large file size.
          </Alert>
        }
      </DialogContent>}
      {/* <Box mt="50px" display={currentARState > ARStatesEnum.INITIALIZATION ? "block" : "none"} >
        <Typography variant="h6" gutterBottom>
          Refresh this page to start again!
        </Typography>
      </Box> */}
      <ARDOMOverlayUI currentARState={currentARState} domId={arUiDomId} getViewer={getViewer} />
    </Dialog>
  );
};

export default ARDialog;