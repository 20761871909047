import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import TextField from '@material-ui/core/TextField';
import ProductHeroLayout from './ProductHeroLayout';
import AugmentedRealityDialog from '../webxr/ARDialog';
import ShareView from '../webxr/ShareView';
import { useParams, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { baseUrl, sampleModelId } from '../webxr/Settings';
import ShareDialog from '../webxr/ShareDialog';
import Link from '@material-ui/core/Link';
import { isMobileDevice } from '../webxr/Utils';

const styles = (theme) => ({
  button: {
    minWidth: 200,
    marginTop: "20px",
  },
  more: {
    marginTop: theme.spacing(2),
  },
  modelIdInput: {
    margin: "20px",
    width: "500px",
    maxWidth: "85%",
  },
  image: {
    width: "150px",
    height: "150px",
    filter: "invert(1)",
    margin: "50px",
  },
  logo: {
    height: "40px",
    display: "inline-block",
    marginRight: "15px",
  },
  title: {
    display: "inline-block",
  },
});

const mongoDbObjectIdRegex = /^[a-f\d]{24}$/i;

function ProductHero(props) {
  const { classes } = props;
  const { pathname, hash } = useLocation();
  const { modelId } = useParams();
  const [arDialogOpened, setARDialogOpened] = React.useState(false);
  const [modelIdInput, setModelIdInput] = React.useState(modelId);
  const [isModelIdValid, setIsModelIdValid] = React.useState(true);
  const [isWebArSupported, setIsWebArSupported] = React.useState(true);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [loadSampleModel, setLoadSampleModel] = React.useState(false);

  React.useEffect(() => {
    if ('xr' in navigator) {
      navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
        if (supported) {
          setIsWebArSupported(true);
        } else {
          setIsWebArSupported(false);
        }
      }).catch((error) => {
        setIsWebArSupported(false);
      });
    } else {
      setIsWebArSupported(false);
    }
  }, []);

  const onSubmit = () => {
    const parsedModelId = getParsedModelId();
    if (!parsedModelId || !parsedModelId.match(mongoDbObjectIdRegex)) {
      setIsModelIdValid(false);
      return;
    }

    setIsModelIdValid(true);
    setARDialogOpened(true);
  };

  const doLoadSampleModel = () => {
    setLoadSampleModel(true);
    setIsModelIdValid(true);
    setARDialogOpened(true);
  };

  const getParsedModelId = () => {
    const tokens = modelIdInput ? modelIdInput.split("/") : [];
    return tokens.length > 1 ? tokens[tokens.length - 1] : modelIdInput;
  };

  const shareLink = () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'CopyLink',
      eventAction: shareableLink,
    });
    if (navigator.share && isMobileDevice()) {
      navigator.share({
        title: 'bimU.io Reality',
        text: isWebArSupported ? "Navigate this BIM model in Augmented Reality! You will need a password to view it." : undefined,
        url: shareableLink
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      setShareDialogOpen(true);
    }
  }

  const replayCurrentModel = () => {
    window.location.replace(shareableLink); // Replace to avoid going back
    window.location.reload(); // Reload to enforce refresh
  }

  const uploadMyModel = () => {
    window.location.href = "https://bimu.io";
  }

  const parsedModelId = getParsedModelId();
  const finalBaseUrl = process.env.NODE_ENV == "production" ? baseUrl : "/";
  const shareableLink = parsedModelId ? `${finalBaseUrl}#/models/${parsedModelId}` : finalBaseUrl;

  // Share view
  if (pathname.includes("share") && modelId) {
    return <ShareView onShareClick={shareLink} onReplayClick={replayCurrentModel} onUploadClick={uploadMyModel} />
  }

  return (
    <ProductHeroLayout>
      <img className={classes.image} src="/images/camera-black.png" />
      <Box>
        <img className={classes.logo} src="/images/logo_large.png" />
        <Typography className={classes.title} color="inherit" align="center" variant="h3">
          Reality
        </Typography>
      </Box>
      <TextField
        className={classes.modelIdInput}
        variant="outlined"
        color="secondary"
        placeholder="Paste your Model ID or Shared Link here..."
        value={modelIdInput}
        onChange={(e) => setModelIdInput(e.target.value)}
        error={!isModelIdValid}
        helperText={!isModelIdValid && "Please enter a valid Model ID or Shared Link."}
      />
      {isWebArSupported &&
        <Button
          color="secondary"
          variant="contained"
          size="large"
          className={classes.button}
          onClick={onSubmit}
        >
          Launch AR
        </Button>
      }
      {!isWebArSupported &&
        <Alert variant="filled" severity="error">
          Your browser does not support Augmented Reality. Please copy the link and use <Link color="textPrimary" underline="always" href="https://apps.apple.com/tw/app/webxr-viewer/id1295998056" target="_blank">WebXR Viewer</Link> on iOS or <Link color="textPrimary" underline="always" href="https://play.google.com/store/apps/details?id=com.android.chrome" target="_blank">Google Chrome</Link> on Android.
        </Alert>
      }
      <Button
        color="secondary"
        variant="contained"
        size="small"
        className={classes.button}
        onClick={shareLink}
      >
        Copy Link
      </Button>
      {isWebArSupported &&
        <Button
          color="secondary"
          variant="contained"
          size="small"
          className={classes.button}
          onClick={doLoadSampleModel}
        >
          Load Sample Model
        </Button>
      }
      <Button
        color="secondary"
        variant="contained"
        size="small"
        className={classes.button}
        onClick={uploadMyModel}
      >
        Upload My Model
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
        Discover BIM in Augmented Reality
      </Typography>
      <AugmentedRealityDialog open={arDialogOpened} closeDialog={() => setARDialogOpened(false)} modelId={loadSampleModel ? sampleModelId : getParsedModelId()} loadSampleModel={loadSampleModel} />
      <ShareDialog open={shareDialogOpen} closeDialog={() => setShareDialogOpen(false)} shareableLink={shareableLink} />
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
